import axios from "axios";

const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
const SolarAPI = {
  validateToken: async (token: string) => {
    const res = await axios.get(api_endpoint + "/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.success) {
      return res.data;
    }
    return null;
  },
 login: async (email: string, password: string) => {
    try {
      const res = await axios.post(
        api_endpoint + "/login",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
        if(res.data)
        return res.data;
      
    } catch (error:any) {
      let customError 
      if (!error.response.data.success) {
        throw new Error('Usuário ou senha incorretos. Por favor, tente novamente.')
      } 
      if (error.response.data.error) {
        throw new Error('Ocorreu um erro durante o login. Por favor, tente novamente mais tarde.')
      };
        throw error
    }
  },
  logout: async (token: string) => {
    const res = await axios.post(api_endpoint + "/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.success) {
      return true;
    }
    return false;
  },
  findAll: async (token: string) => {
    const res = await axios.get(api_endpoint + "/quotes", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.success) {
      return res.data.quotes;
    }
    return null;
  },
  deleteQuote: async (id: number) => {
    const res = await axios.delete(api_endpoint + `/quotes/${id}/delete`);
    if (res.data.success) {
      return true;
    }
    return false;
  },
};

export default SolarAPI;
