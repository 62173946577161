import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/joy/styles";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./contexts/UserProvider";
import { QuoteProvider } from "./contexts/QuoteProvider";


ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <UserProvider>
        <QuoteProvider>
          <App />
        </QuoteProvider>
      </UserProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
