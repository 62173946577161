import React, { useState } from "react";
import { UserContext } from "./UserContext";
import SolarAPI from "../services/SolarAPI";
export const UserProvider = ({ children }: any) => {
  const [user, setUser,] = useState({});
  const [error, setError] = useState(null)

  const login = async(email:string, password:string) => {
    try{
      setError(null)
      const data = await SolarAPI.login(email, password);
      if(data.tokenData && data.user){
          localStorage.setItem("token", data.tokenData['token'])
          setUser((prevUser) => ({ ...prevUser, ...data.user }));
          return true
      }
      return false
    }
    catch(error:any){
      setError(error.message)
    }
  };
  const validateToken = async () => {
    const tokenStorage = localStorage.getItem("token");

    if (tokenStorage) {
      console.log(tokenStorage+'oxe')
      const data = await SolarAPI.validateToken(tokenStorage);
      if (data.user) {
        setUser((prevUser) => ({ ...prevUser, ...data.user }));
        return true
      }
      localStorage.setItem("token", "");
      return false
    }
    return false
  };

  const logout = async () => {
    const tokenStorage = localStorage.getItem("token");
    const loggedOut = await SolarAPI.logout(tokenStorage!);
    if(loggedOut){
      localStorage.setItem("token","");
      setUser((prevUser) => null)
      return true
    }
    return false
  }

  return (
    <UserContext.Provider value={{ user, login,logout, error, validateToken }}>
      {children}
    </UserContext.Provider>
  );
};
