import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import SolarAPI from "../services/SolarAPI";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../redux/reducers/userReducer";
import { UserContext } from "../contexts/UserContext";

interface PrivatePageProps {
  children: React.ReactNode;
}

export default function PrivatePage({ children }: any) {
  const { user, validateToken } = useContext(UserContext);
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (JSON.stringify(user) !== "{}") {
      setLogged(true);
      setLoading(false);
    } else {
      const checkToken = async () => {
        const tokenIsValid = await validateToken();
        setLogged(tokenIsValid);
        setLoading(false);
      };
      checkToken();
    }
  }, []);
  if (loading) {
    return <div>loading</div>;
  }
  if (logged) {
    return children;
  } else {
    return <Navigate to="/"></Navigate>;
  }
}
