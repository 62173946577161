import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivatePage from './pages/Private';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { useContext, useEffect } from 'react';
import { UserContext } from './contexts/UserContext';

export default function JoyOrderDashboardTemplate() {
  const {tokenData, user, validateToken} = useContext(UserContext)
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login></Login>}></Route>
      <Route path="/dashboard" element={<PrivatePage><Dashboard/></PrivatePage>}></Route>
    </Routes>
    </BrowserRouter>
  );
}
