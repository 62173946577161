import React, { useState } from "react";
import { UserContext } from "./UserContext";
import SolarAPI from "../services/SolarAPI";
import { QuoteContext } from "./QuoteContext";
export const QuoteProvider = ({ children }: any) => {
  const [quotes, setQuotes] = useState([]);

  const findAll = async() => {
    const tokenStorage = localStorage.getItem('token')
    if(tokenStorage) {
        const quotes = await SolarAPI.findAll(tokenStorage)
        if(quotes){
            setQuotes({...quotes, quotes});
            return quotes;
        }
        return []
    }
    
  };

  const deleteQuote = async(id:number) => {
    const quotesDeleted = await SolarAPI.deleteQuote(id)
    if(quotesDeleted){
      await findAll()
    }
  }
  
  return (
    <QuoteContext.Provider value={{ quotes, setQuotes, findAll, deleteQuote}}>
      {children}
    </QuoteContext.Provider>
  );
};
